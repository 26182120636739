<template>
  <section class="h-screen">
    <div class="px-6 h-full text-gray-800">
      <div
        class="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6"
      >
        <div
          class="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0"
        >
          <img
            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
            class="w-full"
            alt="Sample image"
          />
        </div>
        <div class="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
          <form @submit.prevent="save">
            <!-- Email input -->
            <div class="mb-6">
              <input
                v-model.trim="validate.name.$model"
                type="text"
                id="email"
                name="email"
                class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :class="{ 'border-theme-6': validate.name.$error }"
                placeholder="Tên đăng nhập"
              />
              <template v-if="validate.name.$error">
                <div v-for="(error, index) in validate.name.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>

            <!-- Password input -->
            <div class="mb-6 relative">
              <input
                v-model.trim="validate.password.$model"
                id="password"
                :type="show ? 'text' : 'password'"
                class="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                :class="{ 'border-theme-6': validate.password.$error }"
                placeholder="Password"
              />
              <div
                class="absolute top-0 right-0 rounded-r w-10 z-50 h-full flex items-center justify-center"
                @click="toggleShow"
              >
                <EyeOffIcon v-if="show" class="w-4 h-4 text-gray-600 "/>
                <EyeIcon v-else class="w-4 h-4 text-gray-600 "/>
              </div>
              <template v-if="validate.password.$error">
                <div v-for="(error, index) in validate.password.$errors" :key="index" class="text-theme-6 mt-2">
                  {{ error.$message }}
                </div>
              </template>
            </div>

            <div class="text-center lg:text-left">
              <button
                type="submit"
                class="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useLogin} from "@/views/login/useLogin";

export default defineComponent({
  data() {
    return {
      show: false,
    };
  },
  setup() {
    const setup = useLogin();
    return {...setup};
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
});
</script>
