import { onMounted, reactive, toRefs } from "vue";
import { minLength, required } from "@vuelidate/validators";
import { authService } from "@/services";
import { useRouter } from "vue-router";
import { showError } from "@/libs/notification";
import { useVuelidate } from "@vuelidate/core";

export const useLogin: any = () => {
  onMounted(() => {
    cash("body")
      .removeClass("app")
      .removeClass("error-page")
      .addClass("login");
  });
  const formData = reactive({
    name: "",
    password: ""
  });
  const router = useRouter();

  const rules = {
    name: {
      required,
      minLength: minLength(2)
    },
    password: {
      required,
      minLength: minLength(6)
    }
  };
  const login = async (username: string, password: string) => {
    try {
      await authService.login(username, password);
      await authService.getUserInfoServer()
      const user = authService.getUserInfoLocal();
      if (user?.role === 'CHILDREN') {
        await router.push("/tra-cuu-don-hang");
      } else {
        await router.push("/");
      }
    } catch (error) {
      showError(
        error.response
          ? error.response.data.error
          : "Tài khoản hoặc mật khẩu không chính xác"
      );
    }
  };
  const validate = useVuelidate(rules, toRefs(formData));
  const save = () => {
    validate.value.$touch();
    if (validate.value.$invalid) {
      showError("Đã xảy ra lỗi");
    } else {
      login(formData.name, formData.password);
    }
  };

  return {
    validate,
    formData,
    save
  };
}
