
import {defineComponent} from "vue";
import {useLogin} from "@/views/login/useLogin";

export default defineComponent({
  data() {
    return {
      show: false,
    };
  },
  setup() {
    const setup = useLogin();
    return {...setup};
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
});
